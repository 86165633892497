<template>
  <div class="text-center overflow-x-scroll">
    <b-button-group
      class="mb-2"
    >
      <b-button
        v-if="startupReports.startupReport"
        :variant="this.$route.name == 'DealerStartupReport' ? 'primary' : 'outline-primary'"
        :to="'/dealers/reports/startup-report/' + this.$route.params.id"
        class="text-nowrap"
      >
        <FeatherIcon icon="BarChartIcon" />
        {{ $t('Girişim Raporu') }}
      </b-button>
      <b-button
        v-if="startupReports.marketAnalysis"
        :variant="this.$route.name == 'DealerMarketAnalysis' ? 'primary' : 'outline-primary'"
        :to="'/dealers/reports/market-analysis/' + this.$route.params.id"
        class="text-nowrap"
      >
        <FeatherIcon icon="CrosshairIcon" />
        {{ $t('Pazar Analizi Raporu') }}
      </b-button>
      <b-button
        v-if="startupReports.businessModelAnalysis"
        :variant="this.$route.name == 'DealerBusinessModelAnalysis' ? 'primary' : 'outline-primary'"
        :to="'/dealers/reports/business-model-analysis/' + this.$route.params.id"
        class="text-nowrap"
      >
        <FeatherIcon icon="BarChart2Icon" />
        {{ $t('İş Modeli Performansı') }}
      </b-button>
      <b-button
        v-if="startupReports.canva"
        :variant="this.$route.name == 'DealerCanva' ? 'primary' : 'outline-primary'"
        :to="'/dealers/reports/canva/' + this.$route.params.id"
        class="text-nowrap"
      >
        <FeatherIcon icon="BoxIcon" />
        {{ $t('İş Modeli Kanvası') }}
      </b-button>
      <b-button
        v-if="startupReports.technologyReadinessLevel"
        :variant="this.$route.name == 'DealerTechnologyReadiness' ? 'primary' : 'outline-primary'"
        :to="'/dealers/reports/technology-readiness-level/' + this.$route.params.id"
        class="text-nowrap"
      >
        <FeatherIcon icon="GlobeIcon" />
        {{ $t('Teknoloji Hazırlık Seviyesi') }}
      </b-button>
      <b-button
        v-if="startupReports.investorSummary"
        :variant="this.$route.name == 'DealerInvestorSummaryReport' ? 'primary' : 'outline-primary'"
        :to="'/dealers/reports/investor-summary/' + this.$route.params.id"
        class="text-nowrap"
      >
        <FeatherIcon icon="PieChartIcon" />
        {{ $t('Yatırımcı Özet Sayfası') }}
      </b-button>
    </b-button-group>
  </div>
</template>

<script>
import {
  BButtonGroup, BButton,
} from 'bootstrap-vue'

export default {
  name: 'RouteButtons',
  components: {
    BButtonGroup,
    BButton,
  },
  data() {
    return {
      windowWidth: window.innerWidth,
    }
  },
  computed: {
    startupReports() {
      return this.$store.getters['dealerReportStartupReport/getStartupReports']
    },
  },
}
</script>
