<template>
  <div>
    <b-row
      v-if="marketAnalysis"
      class="match-height"
    >
      <b-col
        cols="12"
        md="auto"
      >
        <div class="bar-graphic h-100 position-relative">
          <div
            class="bar-line position-absolute"
            :style="'top: ' + marketAnalysis.chartValue + '%'"
          />
          <b-row>
            <b-col
              class="labels"
              cols="12"
            >
              {{ $t('Olgunluk') }}
            </b-col>
            <b-col
              class="labels"
              cols="12"
            >
              {{ $t('Büyüme') }}
            </b-col>
            <b-col
              class="labels"
              cols="12"
            >
              {{ $t('Başlangıç') }}
            </b-col>
          </b-row>
        </div>
      </b-col>
      <b-col md="9">
        <b-card-title class="font-weight-bolder font-large-1 text-primary text-left d-block mb-2">
          {{ marketAnalysis.comment.title }}
        </b-card-title>
        <b-card-text>
          {{ marketAnalysis.comment.text }}
        </b-card-text>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BCardText, BCardTitle,
} from 'bootstrap-vue'

export default {
  name: 'MarketGraphic',
  components: {
    BRow,
    BCol,
    BCardText,
    BCardTitle,
  },
  computed: {
    marketAnalysis() {
      return this.$store.getters['dealerReportMarketAnalysis/getMarketAnalysisReport']
    },
  },
}
</script>

<style scoped lang="sass">
.bar-graphic
  background: linear-gradient(0deg, rgba(70,184,0,1) 0%, rgba(224,194,0,1) 50%, rgba(227,0,0,1) 100%)
  border-radius: 25px
  overflow: hidden
  height: 360px
  width: 120px
  .labels
    color: #ffffff
    font-weight: bold
    text-align: center
    width: 120px !important
    display: block
    height: 120px
    line-height: 120px
    font-size: 18px
  .bar-line
    width: 100%
    background-color: #3b42f4
    height: 7px
    z-index: 10000
</style>
