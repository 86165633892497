<template>
  <div>
    <b-row class="mb-2">
      <b-col
        md="4"
        offset-md="4"
        class="text-center"
      >
        <market-card
          :title="marketAnalysis.data[0].title"
          color="danger"
          :subtitle="marketAnalysis.data[0].subtitle"
        />
      </b-col>
    </b-row>
    <b-row class="mb-2">
      <b-col
        md="4"
        class="text-center"
      >
        <market-card
          :title="marketAnalysis.data[1].title"
          color="danger"
          :subtitle="marketAnalysis.data[1].subtitle"
        />
      </b-col>
      <b-col
        md="4"
        class="text-center"
      >
        <market-card
          :title="marketAnalysis.data[2].title"
          color="success"
          :subtitle="marketAnalysis.data[2].subtitle"
        />
      </b-col>
      <b-col
        md="4"
        class="text-center"
      >
        <market-card
          :title="marketAnalysis.data[3].title"
          color="success"
          :subtitle="marketAnalysis.data[3].subtitle"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col
        md="4"
        offset-md="4"
        class="text-center"
      >
        <market-card
          :title="marketAnalysis.data[4].title"
          color="success"
          :subtitle="marketAnalysis.data[4].subtitle"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import MarketCard from '@/views/Dealers/Reports/components/market_analysis/MarketCard.vue'

export default {
  name: 'MarketPhase',
  components: {
    BRow,
    BCol,
    MarketCard,
  },
  computed: {
    marketAnalysis() {
      return this.$store.getters['dealerReportMarketAnalysis/getMarketAnalysisReport']
    },
  },
}
</script>

<style scoped>
</style>
