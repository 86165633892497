<template>
  <div
    class="market-card p-1 height-100 d-flex align-items-center"
    :class="'bg-gradient-' + color + ' border-' + color"
  >
    <div class="w-100">
      <div class="mb-0 font-weight-bold">
        {{ title }}
      </div>
      <div class="font-weight-light font-small-4 mt-1 text-light">
        {{ subtitle }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MarketCardVue',
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      required: true,
    },
  },
}
</script>
